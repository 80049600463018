<template>
  <v-container fluid>
    <v-toolbar elevation="2" class="mb-4" dense>
      <v-spacer></v-spacer>
      <v-row class="mt-12">
        <v-col cols="12" lg="6" md="6" sm="6">
          <v-checkbox
            v-model="stock"
            label="Afficher tous les articles"
            @change="refresh_stock"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="2" md="2" sm="2" class="mt-2">
          <datepicker
            label="Du : "
            v-model="exercice.dd"
            :edit="true"
            :clearable="false"
          ></datepicker>
        </v-col>
        <v-col cols="12" lg="2" md="2" sm="2" class="mt-2">
          <datepicker
            label="Au : "
            v-model="exercice.df"
            :edit="true"
            :clearable="false"
          ></datepicker>
        </v-col>
        <v-col cols="12" lg="2" md="2" sm="2">
          <v-menu bottom left v-model="showMenu" :close-on-content-click="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="indigo" v-bind="attrs" v-on="on">
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </template>
            <v-flex xs12 style="overflow: auto">
              <v-card class="mx-auto" width="auto" height="auto" tile>
                <v-list>
                  <v-list-item-group color="primary">
                    <v-list-item @click="showlist">
                      <v-list-item-title
                        >Mouvements Cumulés Par
                        Produit/Compte</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="print('c')">
                      <v-list-item-title
                        >Mouvements Cumulés Par Compte</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="print_entree('e')">
                      <v-list-item-title
                        >Entrées Cumulés Par Compte</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="print_entree('s')">
                      <v-list-item-title
                        >Sorties Cumulés Par Compte</v-list-item-title
                      >
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-flex>
          </v-menu>
          <v-btn
            color="indigo"
            class="mx-1"
            @click="regul_stock"
            :loading="loading_data"
            :key="kbt"
            v-if="$store.state.isadmin"
          >
            <v-icon>mdi-wrench-cog-outline</v-icon>
            {{ taux_trait ? taux_trait + " %" : "" }}
          </v-btn>
        </v-col>
      </v-row>

      <template v-slot:extension>
        <v-tabs v-model="tabs" class="my-tabs" @change="form_list = []">
          <v-tab key="1" href="#1" v-if="prd_type == '02' || prd_type == '34'">
            Par Produit
          </v-tab>
          <v-tab key="2" href="#2"> Par Article </v-tab>
          <v-tab key="3" href="#3"> Par Depot </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-tabs-items v-model="tabs">
          <v-tab-item :value="'1'">
            <v-row>
              <v-col
                cols="12"
                sm="4"
                :md="prd_type == '02' || prd_type == '34' ? 5 : 7"
              >
                <listitemspage
                  :selitem="selitem"
                  :qSelect="Qselect"
                  :qSelectall="Qselectall"
                  :PageResponse="'products'"
                  :AllResponse="'allproducts'"
                  :title="'Liste des Produits'"
                  :Filter="true"
                  :unFilter="unfilter"
                  @filter="OpenFilterForm"
                  @unfilter="unfilrer"
                  :headers="product_headers"
                  :CatScope="categories"
                  :VarScope="template"
                  :AttScope="attributs"
                  :Kind="parseInt(prd_type)"
                  :Stock="stock ? null : 1"
                  :field="'CODE'"
                  :filename="'Produits'"
                  :sheetname="'Produits'"
                  @rowselect="ProductChange"
                  :showedit="false"
                  :key="klist"
                  :Add="false"
                  :del="false"
                  :expire1="2"
                  :list_options="false"
                >
                </listitemspage>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                :md="prd_type == '02' || prd_type == '34' ? 7 : 5"
              >
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <listitems
                      :list="articles"
                      :title="'Articles'"
                      :headers="articles_headers"
                      :filename="'Articles'"
                      :sheetname="'Articles'"
                      :master="true"
                      :key="kd"
                      :showexp="true"
                      :showedit="false"
                      :exp_headers="depot2_headers"
                      :exp_data="stock_depots"
                      :val_exp="'stock_cmpt'"
                      :mes_exp="'Stock par Depot'"
                      :exp_excel="false"
                      :add="false"
                      :del="false"
                      :Update="true"
                      :Total="true"
                      @open="show_stock"
                      @rowselect="ArticleChange"
                      @col_btn1_click="show_mvms"
                      @col_btn2_click="show_list"
                      :btnA_disa="!article.id"
                      :btnA_load="loading_mvm"
                    >
                    </listitems>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item :value="'2'">
            <v-row>
              <v-col cols="12" sm="4" md="8">
                <listitemspage
                  :selitem="selitem"
                  :qSelect="Qart_select"
                  :qSelectall="Qart_selectall"
                  :PageResponse="'articles_pages'"
                  :AllResponse="'allarticles'"
                  :title="'Liste des Articles'"
                  :headers="articles_headers"
                  :btn1="
                    $store.state.auth.includes('02059') || $store.state.isadmin
                      ? 'Maj SI sans Liste'
                      : ''
                  "
                  :btn2="
                    $store.state.auth.includes('02059') || $store.state.isadmin
                      ? 'Maj SI avec Liste'
                      : ''
                  "
                  @click1="OpenSIForm('c')"
                  @click2="OpenSIForm('l')"
                  :Kind="parseInt(prd_type)"
                  :Stock="stock ? null : 1"
                  :field="'CODE'"
                  :filename="'Articles'"
                  :sheetname="'Articles'"
                  @rowselect="ArticleChange"
                  :showedit="false"
                  :key="klista"
                  :Add="false"
                  :del="false"
                  :expire1="2"
                  @col_btn1_click="show_mvms"
                  @col_btn2_click="show_list"
                  :list_options="false"
                >
                </listitemspage>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-row>
                  <v-col cols="12" sm="4" md="12">
                    <listitems
                      :list="form_list"
                      :title="'Par Depot'"
                      :headers="depot3_headers"
                      :key="dkey2"
                      :showstd="true"
                      :master="true"
                      :add="false"
                      :del="false"
                      :Update="true"
                      @rowselect="DepotChange"
                      :filename="'Depots'"
                      :sheetname="'Depots'"
                      @col_btn2_click="show_boxs"
                      @change="item_change"
                    >
                    </listitems>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="'3'">
            <v-row>
              <v-col cols="12" sm="4" md="5">
                <listitems
                  :list="depots_list"
                  :title="'Liste des Depots'"
                  :Get_suf="'Depot'"
                  :headers="depot_headers"
                  :key="dkey"
                  :showedit="false"
                  :master="true"
                  :add="false"
                  :del="false"
                  :filename="'Depots'"
                  :sheetname="'Depots'"
                  @rowselect="DepotChange2"
                  :laoding="loading"
                >
                </listitems>
              </v-col>
              <v-col cols="12" sm="4" md="7" v-if="depot2.id > 0">
                <listitemspage
                  :selitem="selitem_art"
                  :qSelect="Qselect_art"
                  :qSelectall="Qselectall_art"
                  :PageResponse="'articles_depot'"
                  :AllResponse="'allarticles_depot'"
                  :title="'Liste des Articles'"
                  :Filter="false"
                  :headers="articles_headers"
                  :field="'CODE'"
                  :filename="'Articles'"
                  :sheetname="'Articles'"
                  @rowselect="ArticleChange"
                  :showedit="false"
                  :Kind="parseInt(prd_type)"
                  :Stock="stock ? null : 1"
                  :VarScope="parseInt(depot2.id)"
                  @col_btn1_click="show_mvms_d"
                  @col_btn2_click="show_list_d"
                  :key="alist"
                  :Add="false"
                  :del="false"
                >
                </listitemspage>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <formlist
      :title="title"
      :list="form_list"
      :showform="!isListClose"
      :listheaders="formheaders"
      :key="frml"
      :imp_excel="imp_excel"
      :btn_save="btn_save"
      :btn_save_dis="btn_save_dis"
      :width="width"
      @excel_import="excel_si"
      @close="CloseDetail"
      @change="list_change"
      @save="save"
      :canprint="print_ok"
      :printdefault="false"
      @print="print_detail"
      :Total="true"
      :master="true"
      :alert="alert"
      :showstd="true"
      :editer="editer"
      :periode="periode"
      @input="alert = false"
      :type_alert="type_alert"
      :message="message"
      :laoding="loading"
    >
    </formlist>

    <filterform
      :showForm="!isFilterClosed"
      :categories_list="cat_list"
      :templates_list="templates"
      :attributes="attributes"
      :values_list="values_list"
      @close="closeFilterForm"
    >
    </filterform>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>

    <confirmdialog ref="confirm" />
  </v-container>
</template>

<script>
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}
import PRODUCTS from "../graphql/Product/PRODUCTS_ARTICLES.gql";
import ARTICLES_DEPOT from "../graphql/Product/ARTICLES_DEPOT.gql";
import ALLARTICLES_DEPOT from "../graphql/Product/ALLARTICLES_DEPOT.gql";
import ALLPRODUCTS from "../graphql/Product/ALLPRODUCTS.gql";
import DATA from "../graphql/Depot/DATA.gql";
import DEPOTS from "../graphql/Depot/DEPOTS.gql";
import MVMS from "../graphql/Product/MVMS.gql";
import PRODUCTS_MVMS from "../graphql/Product/PRODUCTS_MVMS.gql";
import PRODUCTS_COMPTE from "../graphql/Product/PRODUCTS_COMPTE.gql";
import PRODUCT_LIST from "../graphql/Product/PRODUCT_LIST.gql";
import ALLARTICLES from "../graphql/Product/ALLARTICLES.gql";
import ARTICLES_PAGES from "../graphql/Product/ARTICLES_PAGES.gql";
import UPDATE_SI from "../graphql/Product/UPDATE_SI.gql";
import CORRECTSTOCK from "../graphql/Product/CORRECTSTOCK.gql";

import UPDATE_ARTICLES from "../graphql/Product/UPDATE_ARTICLES.gql";
import ARTICLES_MVMS from "../graphql/Mouvment/ARTICLES_MVMS.gql";

import { addHeaders, addFooters, marges2 } from "print/entete.js";
import { spec } from "print/data.js";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    listitems: () => import("../components/ListItems.vue"),
    filterform: () => import("../components/FilterForm.vue"),
    formlist: () => import("../components/FormList.vue"),
    datepicker: () => import("../components/DatePicker.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    prd_type: String,
  },
  data: () => ({
    showMenu: false,
    tabs: "1",
    selitem: {},
    selitem_art: {},
    alert: false,
    print_ok: false,
    type_alert: "info",
    message: "",
    periode: false,
    klist: 1,
    klista: 100,
    kd: 200,
    kf: 300,
    dkey: 400,
    alist: 500,
    dkey2: 600,
    kbt: 700,
    editer: false,
    isFilterClosed: true,
    isListClose: true,
    title: "",
    unfilter: false,
    template: 0,
    categories: [],
    attributs: [],
    cat_list: [],
    templates: [],
    attributes: [],
    values_list: [],
    form_list: [],
    formheaders: [],
    depots_list: [],
    mvms_list: [],
    frml: 600,
    loading: false,
    loading_data: false,
    width: "1000px",
    btn_save_dis: true,
    loading_liste: false,
    loading_mvm: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    si: "c",
    taux_trait: 0,
    stock: false,
    sicheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },

      {
        text: "Code Article",
        value: "code_article",
        selected: true,
      },
      {
        text: "Article",
        value: "label",
        selected: true,
      },
      {
        text: "Qte",
        value: "qte",
        align: "end",
        slot: "cur",
        total: "qte",
        selected: true,
      },
      {
        text: "Pmp",
        value: "pu",
        align: "end",
        slot: "cur",
        selected: true,
      },
      {
        text: "Depot",
        value: "depot",
        selected: true,
      },
    ],
    silheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Code Article",
        value: "code_article",
        selected: true,
        width: "100px",
      },
      {
        text: "Article",
        value: "label",
        selected: true,
        width: "150px",
      },
      {
        text: "Code Item",
        value: "ref",
        selected: true,
      },
      {
        text: "Attribut 1",
        value: "ref0",
        selected: true,
      },
      {
        text: "Attribut 2",
        value: "ref1",
        selected: true,
      },
      {
        text: "Attribut 3",
        value: "ref2",
        selected: true,
      },
      {
        text: "Attribut 4",
        value: "ref3",
        selected: true,
      },
      {
        text: "Attribut 5",
        value: "ref4",
        selected: true,
      },
      {
        text: "Attribut 6",
        value: "ref5",
        selected: true,
      },
      {
        text: "Date peremption",
        value: "expire_date",
        slot: "date",
        selected: true,
      },
      {
        text: "Qte",
        value: "qte",
        align: "end",
        slot: "cur",
        total: "qte",
        width: "150px",
        selected: true,
      },
      {
        text: "Pmp",
        value: "pu",
        align: "end",
        slot: "cur",
        selected: true,
      },
      {
        text: "Depot",
        value: "depot",
        selected: true,
      },
      {
        text: "Nb",
        value: "nb",
        selected: true,
      },
    ],
    colisheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Depot",
        value: "depot_name",
        selected: true,
      },
      {
        text: "Box",
        value: "box",
        hiden: true,
        hoover: true,
        selected: true,
      },
      {
        text: "Quantite initiale",
        value: "qte_rest",
        total: "qte_rest",
        slot: "cur",
        align: "end",
        selected: false,
        dec: 3,
        barre: true,
        column: "qte_stock",
        valeur: 0,
        edit: true,
        type: "qte",
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
      {
        text: "Index",
        value: "code",
        selected: true,
      },
      {
        text: "Code",
        value: "ref",
        selected: true,
        hiden: true,
        edit: true,
        type: "text",
        sm: 6,
        md: 6,
        rules: [(v) => !!v || "Code obligatoire"],
      },
      {
        text: "Date Reception",
        value: "date_rcpt",
        slot: "date",
        selected: true,
      },
      {
        text: "Date Livraison",
        value: "date_liv",
        slot: "date",
        selected: false,
      },
      {
        text: "Fournisseur",
        value: "fournis_name",
        selected: false,
      },
      {
        text: "Client",
        value: "client_name",
        selected: false,
      },
      {
        text: "Etat",
        value: "statut",
        selected: true,
        hiden: true,
        expire: true,
      },
      {
        text: "Stock",
        value: "qte_stock",
        slot: "href22",
        align: "end",
        total: "qte_stock",
        selected: true,
        edit: true,
        type: "qte",
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Stock obligatoire"],
      },
      {
        text: "Date de péremption",
        value: "expire_date",
        slot: "date",
        selected: true,
        hiden: true,
        edit: true,
        type: "date",
        date_max: null,
        dense: false,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Date de péremption obligatoire"],
      },
      {
        text: "Changed",
        value: "changed",
        selected: false,
        hiden: true,
        slot: "checkbox",
      },

      {
        text: "Origine",
        value: "origin",
        selected: true,
      },
      {
        text: "Comment",
        value: "comment",
        selected: false,
      },
      {
        text: "Rack",
        value: "rack",
        hiden: true,
        hoover_list: true,
      },
      {
        text: "Level",
        value: "level_id",
        hiden: true,
        hoover_list: true,
      },
      {
        text: "Position",
        value: "pos_id",
        hiden: true,
        hoover_list: true,
      },
    ],
    product_headers: [
      {
        text: "Code",
        value: "code",
        enum: "CODE",
        selected: true,
      },
      {
        text: "Désignation",
        value: "label",
        enum: "LABEL",
        selected: true,
        expire: true,
      },
      {
        text: "Stock",
        value: "stock_cmpt",
        enum: "STOCK_CMPT",
        align: "end",
        width: "auto",
        selected: true,
        slot: "cur",
        expire: true,
      },
      {
        text: "Seuil alerte",
        value: "seuil_stock_alerte",
        enum: "SEUIL_STOCK_ALERTE",
        align: "end",
        width: "150px",
        selected: false,
        slot: "cur",
      },

      {
        text: "Statut",
        value: "statuts_name",
        enum: "STATUTS_NAME",
        selected: false,
        slot: "chip",
        color: "color",
      },
    ],
    depot2_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Magasin",
        value: "depot_name",
        selected: true,
      },
      {
        text: "Stock",
        value: "stock_cmpt",
        align: "end",
        slot: "cur",
        selected: true,
        edit: true,
        type: "qte",
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Stock obligatoire"],
      },
      {
        text: "",
        slot: "col_btn2",
        selected: true,
        icon: "mdi-playlist-star",
        tooltip: "plus de detail",
        hiden: true,
        sortable: false,
        column1: "NbRack",
        operator1: "!",
        valeur1: "0",
        width: "1%",
      },
      {
        text: "Changed",
        value: "changed",
        selected: false,
        hiden: true,
        slot: "checkbox",
      },
    ],
    depot3_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Magasin",
        value: "depot_name",
        selected: true,
      },
      {
        text: "Stock",
        value: "stock_cmpt",
        align: "end",
        slot: "cur",
        selected: true,
        edit: true,
        type: "qte",
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Stock obligatoire"],
      },
    ],
    depot_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Date creation",
        value: "created_at",
        selected: true,
      },
      {
        text: "Site",
        value: "label",
        selected: true,
        hiden: true,
      },
      {
        text: "Nom",
        value: "label",
        slot: "href",
        selected: true,
      },

      {
        text: "Wilaya",
        value: "departement_name",
        selected: false,
      },
      {
        text: "Ville",
        value: "ville_name",
        selected: false,
      },
      {
        text: "Nombre de Produits",
        value: "NbProduit",
        align: "end",
        slot: "cur",
        dec: 0,
        selected: true,
      },
    ],
    articles_headers: [
      {
        text: "Code",
        value: "code",
        enum: "CODE",
        selected: true,
      },
      {
        text: "Ref.Ext",
        value: "ref_ext",
        enum: "REF_EXT",
        selected: false,
      },
      {
        text: "Produit",
        value: "product_name",
        enum: "PRODUCT_NAME",
        selected: false,
      },
      {
        text: "Article",
        value: "article_name",
        enum: "ARTICLE_NAME",
        spec: true,
        column: "linkcount",
        valeur: 0,
        selected: true,
      },
      {
        text: "Code. Fournisseur ",
        value: "code_fournisseur",
        enum: "CODE_FOURNISSEUR",
        hiden: true,
        selected: false,
      },
      {
        text: "Fournisseur",
        value: "tier_name",
        enum: "TIER_NAME",
        hiden: true,
        selected: true,
      },
      {
        text: "Client",
        value: "client_name",
        enum: "CLIENT_NAME",
        hiden: true,
        selected: true,
      },
      {
        text: "Stock",
        value: "stock_cmpt",
        align: "end",
        enum: "STOCK_CMPT",
        total: "stock_cmpt",
        selected: true,
        slot: "href22",
        edit: true,
        width: "120",
        type: "qte",
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Stock obligatoire"],
      },
      {
        text: "Unité",
        value: "unit",
        selected: true,
      },
      {
        text: "Facteur",
        value: "factor",
        enum: "FACTOR",
        selected: false,
      },
      {
        text: "pmp",
        value: "pmp",
        end: "end",
        slot: "cur",
        enum: "PMP",
        hiden: true,
        selected: false,
      },
      {
        text: "Date dernier mvm",
        value: "date_dern_mvm",
        enum: "DATE_DERN_MVM",
        slot: "date",
        selected: false,
      },
      {
        text: "Statut",
        value: "statuts_name",
        enum: "STATUTS_NAME",
        selected: false,
        slot: "chip",
        color: "color",
      },
      {
        text: "",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-swap-horizontal-bold",
        tooltip: "mouvements de stock de l'article",

        sortable: false,
        width: "1%",
      },
      {
        text: "",
        slot: "col_btn2",
        selected: true,
        icon: "mdi-playlist-star",
        tooltip: "Liste des Items",
        sortable: false,
        column1: "bylist",
        valeur1: "1",
        column2: "linkcount",
        valeur2: "0",
        width: "1%",
      },
    ],
    mvmsheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },

      {
        text: "Entrepôt",
        value: "depot_name",
        selected: true,
      },
      {
        text: "Reference",
        value: "nobon",
        selected: true,
      },
      {
        text: "Type",
        value: "type_opr",
        selected: true,
      },

      {
        text: "Date mouvement",
        value: "mvm_date",
        slot: "date",
        selected: true,
      },
      {
        text: "Tier",
        value: "tier_name",
        selected: true,
      },

      {
        text: "Entree",
        value: "entree",
        align: "end",
        slot: "cur33",
        total: "entree",
        selected: true,
        dec: 2,
      },
      {
        text: "Sortie",
        value: "sortie",
        align: "end",
        slot: "cur33",
        total: "sortie",
        selected: true,
        dec: 2,
      },
      {
        text: "Destocké",
        value: "destocke",
        hiden: true,
        selected: true,
        dec: 2,
      },
      {
        text: "Valeur",
        value: "total",
        align: "end",
        slot: "cur22",
        selected: true,
      },
    ],
    boxheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Rack",
        value: "rack",
        selected: true,
      },
      {
        text: "Box",
        value: "box",
        selected: true,
      },
      {
        text: "Niveau",
        value: "level_id",
        selected: true,
      },
      {
        text: "Position",
        value: "pos_id",
        selected: true,
      },
      {
        text: "Quantité",
        value: "stock_cmpt",
        total: "stock_cmpt",
        slot: "cur",
        align: "end",
        selected: true,
      },
    ],
    product: {},
    article: {},
    depot: {},
    depot2: {},
    exercice: {},
    imp_excel: false,
    btn_save: false,
  }),

  computed: {
    spec() {
      return spec;
    },
    depots() {
      return this.$store.state.depots.filter((elm) => elm.ChildCount == 0);
    },
    Qart_select() {
      return ARTICLES_PAGES;
    },
    Qart_selectall() {
      return ALLARTICLES;
    },
    Qselect_art() {
      return ARTICLES_DEPOT;
    },
    Qselectall_art() {
      return ALLARTICLES_DEPOT;
    },
    Qselect() {
      return PRODUCTS;
    },
    Qselectall() {
      return ALLPRODUCTS;
    },
    articles() {
      return this.product ? this.product.articles : [];
    },
    stock_depots() {
      return this.article ? this.article.stock_depots : [];
    },
    stock_fournisseurs() {
      return this.product ? this.product.stock_fournisseurs : [];
    },
    colis_attributs() {
      return this.attributes.filter((elm) => elm.type_attr == "colis");
    },
  },
  watch: {
    prd_type: {
      handler() {
        this.articles_headers[4].hiden = this.prd_type == "03";
        this.articles_headers[5].hiden = this.prd_type == "03";
        this.articles_headers[6].hiden =
          this.prd_type == "02" || this.prd_type == "34" || !this.spec;
        this.klist++;
        this.klista++;
      },
      deep: true,
    },
  },
  created() {
    this.exercice.dd = this.$store.state.exercice.toString() + "-01-01";
    this.exercice.df = new Date().toISOString().slice(0, 10);
  },

  async mounted() {
    let v;
    this.articles_headers[4].hiden = this.prd_type == "03";
    this.articles_headers[5].hiden = this.prd_type == "03";
    this.articles_headers[6].hiden =
      this.prd_type == "02" || this.prd_type == "34" || !this.spec;
    this.articles_headers[10].hiden = !(
      this.$store.state.auth.includes("01027") || this.$store.state.isadmin
    );
    if (this.prd_type == "02" || this.prd_type == "34")
      v = {
        where: { column: "TOBUY", value: "1" },
      };
    if (this.prd_type == "03")
      v = {
        where: { column: "TOSELL", value: "1" },
      };
    if (this.prd_type == "04")
      v = {
        where: {
          AND: [{ column: "TOMANUFACTURE", value: "1" }],
        },
      };
    let r = await this.requette(DATA, v);
    if (r) {
      this.templates = r.alltemplates;
      this.attributes = r.allattributes;
      this.values_list = r.allvalues;
      this.cat_list = r.categorie;
    }
    r = await this.requette(DEPOTS, v);
    if (r) {
      if (this.$store.state.me.depots) {
        this.depots_list = r.depots.filter((elm) =>
          this.$store.state.me.depots.includes(elm.id)
        );
        this.dkey++;
      }
    }
  },

  methods: {
    refresh_stock() {
      if (this.tabs == 1) this.klist++;
      if (this.tabs == 2) this.klista++;
      if (this.tabs == 3) this.alist++;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    datefr(date, time = false, long = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: long ? "long" : "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    async showlist() {
      let v = {
        date_debut: this.exercice.dd,
        date_fin: this.exercice.df,
        tosell: this.prd_type == "03" ? 1 : null,
        tobuy: this.prd_type == "02" || this.prd_type == "34" ? 1 : null,
        tomanufacture: this.prd_type == "04" ? 1 : null,
      };
      let r;
      let mvms_list = [];

      r = await this.requette(PRODUCTS_MVMS, v);
      if (r) mvms_list = r.products_mvm;

      let mvms_headers = [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Compte Comptable",
          value: "compte",
          selected: true,
          align: "left",
        },
        {
          text: "Code",
          value: "code",
          selected: true,
          align: "left",
        },
        {
          text: "Designation",
          value: "label",
          selected: true,
          align: "left",
        },
        {
          text: "Qte ",
          value: "stock_ini",
          slot: "cur",
          align: "end",
          selected: true,
        },
        {
          text: "Valeur ",
          value: "val_ini",
          slot: "cur",
          align: "end",
          selected: true,
        },
        {
          text: "Qte ",
          value: "stock_in",
          slot: "cur",
          align: "end",
          selected: true,
        },
        {
          text: "Valeur ",
          value: "val_in",
          slot: "cur",
          align: "end",
          selected: true,
        },
        {
          text: "Qte ",
          value: "stock_out",
          slot: "cur",
          align: "end",
          selected: true,
        },
        {
          text: "Valeur ",
          value: "val_out",
          slot: "cur",
          align: "end",
          selected: true,
        },
        {
          text: "Qte ",
          value: "stock_cmpt",
          slot: "cur",
          align: "end",
          selected: true,
        },
        {
          text: "PU ",
          value: "pu",
          slot: "cur",
          align: "end",
          dec: 2,
          selected: true,
        },
        {
          text: "Valeur ",
          value: "val_cmpt",
          slot: "cur",
          align: "end",
          dec: 2,
          selected: true,
        },
      ];
      this.form_list = mvms_list;
      this.formheaders = mvms_headers;
      this.width = "1400px";
      this.btn_save = false;
      this.imp_excel = false;
      this.print_ok = true;
      this.periode = false;
      this.isListClose = false;
      this.frml++;
    },
    async print_detail() {
      if (this.form_list.length > 0) {
        this.form_list.sort((a, b) =>
          a.compte
            ? a.compte.localeCompare(b.compte)
            : "" || a.label
            ? a.label.localeCompare(b.label)
            : ""
        );
        let compte_list = this.form_list.map((elm) => elm.compte);
        var compte = compte_list.filter(onlyUnique);
        compte.sort((a, b) => (a ? a.localeCompare(b) : ""));
        let head = [];
        let columns = [];
        let items = [];
        let foot = [];
        let item;
        //data formating
        let mvms_headers = [
          {
            text: "No",
            value: "no",
            selected: true,
            align: "center",
            width: 10,
          },
          {
            text: "Code",
            value: "code",
            selected: true,
            align: "left",
          },
          {
            text: "Designation",
            value: "label",
            selected: true,
            width: "200px",
            align: "left",
          },
          {
            text: "Qte ",
            value: "stock_ini",
            slot: "cur",
            align: "end",
            width: "60px",
            selected: true,
          },
          {
            text: "Valeur ",
            value: "val_ini",
            slot: "cur2",
            align: "end",
            width: "60px",
            selected: true,
          },
          {
            text: "Qte ",
            value: "stock_in",
            slot: "cur",
            align: "end",
            width: "60px",
            selected: true,
          },
          {
            text: "Valeur ",
            value: "val_in",
            slot: "cur2",
            align: "end",
            width: "60px",
            selected: true,
          },
          {
            text: "Qte ",
            value: "stock_out",
            slot: "cur",
            align: "end",
            width: "60px",
            selected: true,
          },
          {
            text: "Valeur ",
            value: "val_out",
            slot: "cur2",
            align: "end",
            width: "60px",
            selected: true,
          },
          {
            text: "Qte ",
            value: "stock_cmpt",
            slot: "cur",
            align: "end",
            width: "60px",
            selected: true,
          },
          {
            text: "PU ",
            value: "pu",
            slot: "cur",
            align: "end",
            width: "60px",
            selected: true,
          },
          {
            text: "Valeur ",
            value: "val_cmpt",
            slot: "cur2",
            align: "end",
            width: "60px",
            selected: true,
          },
        ];
        let mvms2_headers = [
          {
            text: "",
            value: "no",
            selected: true,
            align: "center",
            colspan: 1,
            width: 10,
          },

          {
            text: "",
            value: "code",
            selected: true,
            colspan: 1,
            align: "left",
          },
          {
            text: "",
            value: "label",
            selected: true,
            width: "200px",
            colspan: 1,
            align: "left",
          },
          {
            text: "Stock Initial ",
            align: "center",
            colspan: 2,
            selected: true,
          },
          {
            text: "Entrees ",
            align: "center",
            colspan: 2,
            selected: true,
          },
          {
            text: "Sorties ",
            align: "center",
            colspan: 2,
            selected: true,
          },
          {
            text: "Stock Final ",
            align: "center",
            colspan: 3,
            selected: true,
          },
        ];

        let columnStyles = {};
        var doc = new jsPDF("l");
        let marges = marges2(doc, this.$store);
        item = {};
        let h1 = [];
        mvms2_headers.forEach((element) => {
          h1.push({
            content: element.text,
            colSpan: element.colspan,
            styles: {
              halign:
                element.align == "end"
                  ? "right"
                  : element.align == "center"
                  ? "center"
                  : "left",
            },
          });
        });

        let h2 = [];
        mvms_headers.forEach((element) => {
          h2.push({
            content: element.text,
            styles: {
              halign:
                element.align == "end"
                  ? "right"
                  : element.align == "center"
                  ? "center"
                  : "left",
            },
          });

          columns.push({ dataKey: element.value });
          columnStyles[element.value] = {
            halign:
              element.align == "end"
                ? "right"
                : element.align == "center"
                ? "center"
                : "left",
            cellWidth:
              element.slot == "cur" ? 18 : element.slot == "cur2" ? 23 : "auto",
            fontSize: 9,
          };
        });
        head.push(h1);
        head.push(h2);
        doc.setFontSize(18);
        doc.setFont("helvetica", "normal");
        doc.text("Mouvements du Stock Par Produit", 100, 50);
        doc.setFontSize(9);
        doc.text(
          "Du :" +
            this.datefr(this.exercice.dd) +
            " Au :" +
            this.datefr(this.exercice.df),
          125,
          55
        );
        doc.text("Alger le : " + this.datefr(this.$store.state.today), 250, 50);
        doc.setFont("helvetica", "bold");
        for (let i = 0; i < compte.length; i++) {
          const cmpt = compte[i];
          let c_label = "";
          let j = this.form_list.findIndex((elm) => elm.compte == cmpt);
          if (j >= 0) c_label = this.form_list[j].compte_label;
          doc.text(
            "Compte Comptable :" + (cmpt ? cmpt + " " + c_label : "Non défini"),
            10,
            60
          );
          items = [];
          let l = this.form_list.filter((elm) => elm.compte == cmpt);
          for (let index = 0; index < l.length; index++) {
            item = {};
            mvms_headers.forEach((elm) => {
              if (elm.value == "no") {
                item[elm.value] = index + 1;
              }
              if (elm.slot == "cur" && l[index][elm.value]) {
                item[elm.value] = this.numberWithSpace(
                  parseFloat(l[index][elm.value]).toFixed(2)
                );
              } else if (elm.slot == "cur2" && l[index][elm.value]) {
                item[elm.value] = this.numberWithSpace(
                  parseFloat(l[index][elm.value]).toFixed(2)
                );
              } else if (l[index][elm.value])
                item[elm.value] = l[index][elm.value];
            });
            items.push(item);
          }
          foot = [];

          foot.push({
            no: {
              content: "S/T: " + (cmpt ? cmpt + " " + c_label : "Non défini"),
              colSpan: 3,
              styles: { halign: "left" },
            },
            stock_ini: {
              content: this.numberWithSpace(
                l.reduce((sum, el) => sum + el.stock_ini, 0).toFixed(2)
              ),
              styles: { halign: "right" },
            },
            val_ini: {
              content: this.numberWithSpace(
                l.reduce((sum, el) => sum + el.val_ini, 0).toFixed(2)
              ),
              styles: { halign: "right" },
            },
            stock_in: {
              content: this.numberWithSpace(
                l.reduce((sum, el) => sum + el.stock_in, 0).toFixed(2)
              ),
              styles: { halign: "right" },
            },
            val_in: {
              content: this.numberWithSpace(
                l.reduce((sum, el) => sum + el.val_in, 0).toFixed(2)
              ),
              styles: { halign: "right" },
            },
            stock_out: {
              content: this.numberWithSpace(
                l.reduce((sum, el) => sum + el.stock_out, 0).toFixed(2)
              ),
              styles: { halign: "right" },
            },
            val_out: {
              content: this.numberWithSpace(
                l.reduce((sum, el) => sum + el.val_out, 0).toFixed(2)
              ),
              styles: { halign: "right" },
            },
            stock_cmpt: {
              content: this.numberWithSpace(
                l.reduce((sum, el) => sum + el.stock_cmpt, 0).toFixed(2)
              ),
              styles: { halign: "right" },
            },
            val_cmpt: {
              content: this.numberWithSpace(
                l.reduce((sum, el) => sum + el.val_cmpt, 0).toFixed(2)
              ),
              styles: { halign: "right" },
            },
          });
          if (i == compte.length - 1) {
            let s_ini = this.form_list.reduce(
              (sum, el) => sum + el.stock_ini,
              0
            );
            let v_ini = this.form_list.reduce((sum, el) => sum + el.val_ini, 0);
            let s_in = this.form_list.reduce((sum, el) => sum + el.stock_in, 0);
            let v_in = this.form_list.reduce((sum, el) => sum + el.val_in, 0);
            let s_out = this.form_list.reduce(
              (sum, el) => sum + el.stock_out,
              0
            );
            let v_out = this.form_list.reduce((sum, el) => sum + el.val_out, 0);
            let s_cmpt = s_ini + s_in - s_out;
            let v_cmpt = v_ini + v_in - v_out;
            foot.push({
              no: { content: "Total", colSpan: 3, halign: "left" },
              stock_ini: {
                content: this.numberWithSpace(s_ini.toFixed(2)),
                styles: { halign: "right" },
              },
              val_ini: {
                content: this.numberWithSpace(v_ini.toFixed(2)),
                styles: { halign: "right" },
              },
              stock_in: {
                content: this.numberWithSpace(s_in.toFixed(2)),
                styles: { halign: "right" },
              },
              val_in: {
                content: this.numberWithSpace(v_in.toFixed(2)),
                styles: { halign: "right" },
              },
              stock_out: {
                content: this.numberWithSpace(s_out.toFixed(2)),
                styles: { halign: "right" },
              },
              val_out: {
                content: this.numberWithSpace(v_out.toFixed(2)),
                styles: { halign: "right" },
              },
              stock_cmpt: {
                content: this.numberWithSpace(s_cmpt.toFixed(2)),
                styles: { halign: "right" },
              },
              val_cmpt: {
                content: this.numberWithSpace(v_cmpt.toFixed(2)),
                styles: { halign: "right" },
              },
            });
          }
          doc.autoTable({
            margin: { top: 65, bottom: marges.margin_bottom + marges.p_h },
            didDrawPage: function (data) {
              // Reseting top margin. The change will be reflected only after print the first page.
              data.settings.margin.top = 50;
            },
            headStyles: {
              valign: "middle",
              fillColor: [128, 128, 128],
              fontSize: 9,
            },
            columnStyles: columnStyles,
            head: head,
            body: items,
            columns: columns,
            foot: foot,
            theme: "striped",
            footStyles: {
              fillColor: [128, 128, 128],
              fontSize: 9,
            },
            showHead: "everyPage",
            showFoot: "lastPage",
          });
          if (i < compte.length - 1) doc.addPage();
        }

        addHeaders(doc, this.$store);
        addFooters(doc, this.$store);

        doc.setProperties({ title: "Etat des stocks" });
        doc.output("pdfobjectnewwindow");
      }
    },
    async print_entree(e) {
      let v = {
        date_debut: this.exercice.dd,
        date_fin: this.exercice.df,
        tosell: this.prd_type == "03" ? 1 : null,
        tobuy: this.prd_type == "02" || this.prd_type == "34" ? 1 : null,
        tomanufacture: this.prd_type == "04" ? 1 : null,
      };
      let r;
      let mvms_list = [];

      r = await this.requette(PRODUCTS_MVMS, v);
      if (r) {
        if (e == "e")
          mvms_list = r.products_mvm.filter(
            (elm) => elm.val_in > 0 || elm.stock_in > 0
          );
        if (e == "s")
          mvms_list = r.products_mvm.filter(
            (elm) => elm.val_out > 0 || elm.stock_out > 0
          );
      }

      if (mvms_list.length > 0) {
        mvms_list.sort((a, b) =>
          a.compte
            ? a.compte.localeCompare(b.compte)
            : "" || a.label
            ? a.label.localeCompare(b.label)
            : ""
        );
        let compte_list = mvms_list.map((elm) => elm.compte);
        var compte = compte_list.filter(onlyUnique);
        compte.sort((a, b) => (a ? a.localeCompare(b) : ""));
        let head = [];
        let columns = [];
        let items = [];
        let foot = [];
        let item;
        //data formating
        let mvms_headers = [
          {
            text: "No",
            value: "no",
            selected: true,
            align: "center",
            width: 10,
          },
          {
            text: "Code",
            value: "code",
            selected: true,
            align: "left",
          },
          {
            text: "Designation",
            value: "label",
            selected: true,
            width: "200px",
            align: "left",
          },

          {
            text: "Qte ",
            value: "stock_in",
            slot: "cur",
            align: "end",
            width: "60px",
            selected: true,
          },
          {
            text: "Valeur ",
            value: "val_in",
            slot: "cur2",
            align: "end",
            width: "60px",
            selected: true,
          },
        ];
        mvms_headers[3].value = e == "e" ? "stock_in" : "stock_out";
        mvms_headers[4].value = e == "e" ? "val_in" : "val_out";
        let mvms2_headers = [
          {
            text: "",
            value: "no",
            selected: true,
            align: "center",
            colspan: 1,
            width: 10,
          },

          {
            text: "",
            value: "code",
            selected: true,
            colspan: 1,
            align: "left",
          },
          {
            text: "",
            value: "label",
            selected: true,
            width: "200px",
            colspan: 1,
            align: "left",
          },
          {
            text: "Entrees ",
            align: "center",
            colspan: 2,
            selected: true,
          },
        ];
        mvms2_headers[3].text = e == "s" ? "Sorties" : "Entrees";
        let columnStyles = {};
        var doc = new jsPDF("p");
        let marges = marges2(doc, this.$store);
        item = {};
        let h1 = [];
        mvms2_headers.forEach((element) => {
          h1.push({
            content: element.text,
            colSpan: element.colspan,
            styles: {
              halign:
                element.align == "end"
                  ? "right"
                  : element.align == "center"
                  ? "center"
                  : "left",
            },
          });
        });

        let h2 = [];
        mvms_headers.forEach((element) => {
          h2.push({
            content: element.text,
            styles: {
              halign:
                element.align == "end"
                  ? "right"
                  : element.align == "center"
                  ? "center"
                  : "left",
            },
          });

          columns.push({ dataKey: element.value });
          columnStyles[element.value] = {
            halign:
              element.align == "end"
                ? "right"
                : element.align == "center"
                ? "center"
                : "left",
            cellWidth:
              element.slot == "cur" ? 18 : element.slot == "cur2" ? 23 : "auto",
            fontSize: 9,
          };
        });
        head.push(h1);
        head.push(h2);
        doc.setFontSize(18);
        doc.setFont("helvetica", "normal");
        if (e == "e")
          doc.text(
            e == "e" ? "Etat des Réceptions " : "Etat des Sorties",
            70,
            50
          );
        doc.setFontSize(9);
        doc.text(
          "Du :" +
            this.datefr(this.exercice.dd) +
            " Au :" +
            this.datefr(this.exercice.df),
          75,
          55
        );
        doc.text("Alger le : " + this.datefr(this.$store.state.today), 160, 50);
        doc.setFont("helvetica", "bold");
        for (let i = 0; i < compte.length; i++) {
          const cmpt = compte[i];
          let c_label = "";
          let j = mvms_list.findIndex((elm) => elm.compte == cmpt);
          if (j >= 0) c_label = mvms_list[j].compte_label;
          doc.text(
            "Compte Comptable :" + (cmpt ? cmpt + " " + c_label : "Non défini"),
            10,
            60
          );
          items = [];
          let l = mvms_list.filter((elm) => elm.compte == cmpt);
          for (let index = 0; index < l.length; index++) {
            item = {};
            mvms_headers.forEach((elm) => {
              if (elm.value == "no") {
                item[elm.value] = index + 1;
              }
              if (elm.slot == "cur" && l[index][elm.value]) {
                item[elm.value] = this.numberWithSpace(
                  parseFloat(l[index][elm.value]).toFixed(2)
                );
              } else if (elm.slot == "cur2" && l[index][elm.value]) {
                item[elm.value] = this.numberWithSpace(
                  parseFloat(l[index][elm.value]).toFixed(2)
                );
              } else if (l[index][elm.value])
                item[elm.value] = l[index][elm.value];
            });
            items.push(item);
          }
          foot = [];
          if (e == "e")
            foot.push({
              no: {
                content: "S/T: " + (cmpt ? cmpt + " " + c_label : "Non défini"),
                colSpan: 3,
                styles: { halign: "left" },
              },
              stock_in: {
                content: this.numberWithSpace(
                  l.reduce((sum, el) => sum + el.stock_in, 0).toFixed(2)
                ),
                styles: { halign: "right" },
              },
              val_in: {
                content: this.numberWithSpace(
                  l.reduce((sum, el) => sum + el.val_in, 0).toFixed(2)
                ),
                styles: { halign: "right" },
              },
            });
          if (e == "s")
            foot.push({
              no: {
                content: "S/T: " + (cmpt ? cmpt + " " + c_label : "Non défini"),
                colSpan: 3,
                styles: { halign: "left" },
              },
              stock_out: {
                content: this.numberWithSpace(
                  l.reduce((sum, el) => sum + el.stock_out, 0).toFixed(2)
                ),
                styles: { halign: "right" },
              },
              val_out: {
                content: this.numberWithSpace(
                  l.reduce((sum, el) => sum + el.val_out, 0).toFixed(2)
                ),
                styles: { halign: "right" },
              },
            });
          if (i == compte.length - 1) {
            let s_in = mvms_list.reduce(
              (sum, el) => sum + (e == "e" ? el.stock_in : el.stock_out),
              0
            );
            let v_in = mvms_list.reduce(
              (sum, el) => sum + (e == "e" ? el.val_in : el.val_out),
              0
            );
            if (e == "e")
              foot.push({
                no: { content: "Total", colSpan: 3, halign: "left" },
                stock_in: {
                  content: this.numberWithSpace(s_in.toFixed(2)),
                  styles: { halign: "right" },
                },
                val_in: {
                  content: this.numberWithSpace(v_in.toFixed(2)),
                  styles: { halign: "right" },
                },
              });
            if (e == "s")
              foot.push({
                no: { content: "Total", colSpan: 3, halign: "left" },
                stock_out: {
                  content: this.numberWithSpace(s_in.toFixed(2)),
                  styles: { halign: "right" },
                },
                val_out: {
                  content: this.numberWithSpace(v_in.toFixed(2)),
                  styles: { halign: "right" },
                },
              });
          }
          doc.autoTable({
            margin: { top: 65, bottom: marges.margin_bottom + marges.p_h },
            didDrawPage: function (data) {
              // Reseting top margin. The change will be reflected only after print the first page.
              data.settings.margin.top = 50;
            },
            headStyles: {
              valign: "middle",
              fillColor: [128, 128, 128],
              fontSize: 9,
            },
            columnStyles: columnStyles,
            head: head,
            body: items,
            columns: columns,
            foot: foot,
            theme: "striped",
            footStyles: {
              fillColor: [128, 128, 128],
              fontSize: 9,
            },
            showHead: "everyPage",
            showFoot: "lastPage",
          });
          if (i < compte.length - 1) doc.addPage();
        }

        addHeaders(doc, this.$store);
        addFooters(doc, this.$store);

        doc.setProperties({ title: "Etat des receptions" });
        doc.output("pdfobjectnewwindow");
      }
    },
    async print(e) {
      let v = {
        date_debut: this.exercice.dd,
        date_fin: this.exercice.df,
        tosell: this.prd_type == "03" ? 1 : null,
        tobuy: this.prd_type == "02" || this.prd_type == "34" ? 1 : null,
        tomanufacture: this.prd_type == "04" ? 1 : null,
      };
      let r;
      let mvms_list = [];
      if (e == "e" || e == "s") {
        r = await this.requette(PRODUCTS_MVMS, v);
        if (r) mvms_list = r.products_mvm;
      }

      if (e == "c") {
        r = await this.requette(PRODUCTS_COMPTE, v);
        if (r) mvms_list = r.products_compte;
      }
      let list = mvms_list;
      if (e == "e")
        list = mvms_list.filter((elm) => elm.val_in > 0 || elm.stock_in > 0);
      if (e == "s")
        list = mvms_list.filter((elm) => elm.val_out > 0 || elm.stock_out > 0);
      if (list.length > 0) {
        if (e == "e" || e == "s")
          list.sort((a, b) =>
            a.compte
              ? a.compte.localeCompare(b.compte)
              : "" || a.label
              ? a.label.localeCompare(b.label)
              : ""
          );
        let head = [];
        let columns = [];
        let items = [];
        let foot = [];
        let item;
        //data formating
        let mvms_headers = [
          {
            text: "No",
            value: "no",
            selected: true,
            align: "center",
            width: 10,
          },
          {
            text: "Compte",
            value: "compte",
            selected: true,
            align: "left",
          },
          {
            text: "Code",
            value: "code",
            selected: true,
            align: "left",
          },
          {
            text: "Designation",
            value: "label",
            selected: true,
            width: "200px",
            align: "left",
          },
          {
            text: "Qte",
            value: "stock_ini",
            slot: "cur",
            align: "end",
            width: "60px",
            hiden: true,
            selected: true,
          },
          {
            text: "Valeur",
            value: "val_ini",
            slot: "cur2",
            align: "end",
            width: "60px",
            hiden: true,
            selected: true,
          },
          {
            text: "Qte",
            value: "stock_in",
            slot: "cur",
            align: "end",
            width: "60px",
            hiden: true,
            selected: true,
          },
          {
            text: "Valeur",
            value: "val_in",
            slot: "cur2",
            align: "end",
            width: "60px",
            hiden: true,
            selected: true,
          },
          {
            text: "Qte",
            value: "stock_out",
            slot: "cur",
            align: "end",
            width: "60px",
            hiden: true,
            selected: true,
          },
          {
            text: "Valeur",
            value: "val_out",
            slot: "cur2",
            align: "end",
            width: "60px",
            hiden: true,
            selected: true,
          },
          {
            text: "Qte cmpt",
            value: "stock_cmpt",
            slot: "cur",
            align: "end",
            width: "60px",
            hiden: true,
            selected: true,
          },
          {
            text: "Valeur ",
            value: "val_cmpt",
            slot: "cur2",
            align: "end",
            width: "60px",
            hiden: true,
            selected: true,
          },
        ];
        let mvms2_headers = [
          {
            text: "",
            value: "no",
            selected: true,
            align: "center",
            colspan: 1,
            width: 10,
          },
          {
            text: "",
            value: "categorie",
            selected: true,
            colspan: 1,
            align: "left",
          },
          {
            text: "",
            value: "code",
            selected: true,
            colspan: 1,
            align: "left",
          },
          {
            text: "",
            value: "label",
            selected: true,
            width: "200px",
            colspan: 1,
            align: "left",
          },
          {
            text: "Stock Initial ",
            align: "center",
            colspan: 2,
            hiden: true,
            selected: true,
          },
          {
            text: "Entrees ",
            align: "center",
            colspan: 2,
            hiden: true,
            selected: true,
          },
          {
            text: "Sorties ",
            align: "center",
            colspan: 2,
            hiden: true,
            selected: true,
          },
          {
            text: "Stock Final ",
            align: "center",
            colspan: 3,
            hiden: true,
            selected: true,
          },
        ];

        mvms_headers[4].hiden = true; //qte ini
        mvms_headers[5].hiden = e == "e" || e == "s"; //val ini
        mvms_headers[6].hiden = e == "c" || e == "s"; //qte in
        mvms_headers[7].hiden = e == "s"; //val in
        mvms_headers[8].hiden = e == "c" || e == "e"; //qte out
        mvms_headers[9].hiden = e == "e"; //val out
        mvms_headers[10].hiden = true;
        mvms_headers[11].hiden = e == "e" || e == "s";

        mvms2_headers[4].hiden = e != "c";
        mvms2_headers[5].hiden = e == "s";
        mvms2_headers[6].hiden = e == "e";
        mvms2_headers[7].hiden = e != "c";

        mvms2_headers[4].colspan = e == "c" ? 1 : 2;
        mvms2_headers[5].colspan = e == "c" ? 1 : 2;
        mvms2_headers[6].colspan = e == "c" ? 1 : 2;
        mvms2_headers[7].colspan = e == "c" ? 1 : 2;
        mvms_headers[5].text = e == "c" ? "" : "Valeur";
        mvms_headers[7].text = e == "c" ? "" : "Valeur";
        mvms_headers[9].text = e == "c" ? "" : "Valeur";
        mvms_headers[11].text = e == "c" ? "" : "Valeur";
        for (let index = 0; index < list.length; index++) {
          item = {};
          mvms_headers
            .filter((elm2) => elm2.hiden != true)
            .forEach((elm) => {
              if (elm.value == "no") {
                item[elm.value] = index + 1;
              }
              if (elm.slot == "cur" && list[index][elm.value]) {
                item[elm.value] = this.numberWithSpace(
                  parseFloat(list[index][elm.value]).toFixed(2)
                );
              } else if (elm.slot == "cur2" && list[index][elm.value]) {
                item[elm.value] = this.numberWithSpace(
                  parseFloat(list[index][elm.value]).toFixed(2)
                );
              } else if (list[index][elm.value])
                item[elm.value] = list[index][elm.value];
            });
          items.push(item);
        }
        let columnStyles = {};
        var doc = new jsPDF("l");
        let marges = marges2(doc, this.$store);
        item = {};
        let h1 = [];
        mvms2_headers
          .filter((elm2) => elm2.hiden != true)
          .forEach((element) => {
            h1.push({
              content: element.text,
              colSpan: element.colspan,
              styles: {
                halign:
                  element.align == "end"
                    ? "right"
                    : element.align == "center"
                    ? "center"
                    : "left",
              },
            });
          });

        let h2 = [];
        mvms_headers
          .filter((elm2) => elm2.hiden != true)
          .forEach((element) => {
            h2.push({
              content: element.text,
              styles: {
                halign:
                  element.align == "end"
                    ? "right"
                    : element.align == "center"
                    ? "center"
                    : "left",
              },
            });

            columns.push({ dataKey: element.value });
            columnStyles[element.value] = {
              halign:
                element.align == "end"
                  ? "right"
                  : element.align == "center"
                  ? "center"
                  : "left",
              cellWidth:
                element.slot == "cur"
                  ? 18
                  : element.slot == "cur2"
                  ? 23
                  : "auto",
              fontSize: 9,
            };
          });
        head.push(h1);
        head.push(h2);
        foot.push({
          no: { content: "Total", colSpan: 3, halign: "left" },
          stock_ini: {
            content: this.numberWithSpace(
              list.reduce((sum, el) => sum + el.stock_ini, 0).toFixed(2)
            ),
            styles: { halign: "right" },
          },
          val_ini: {
            content: this.numberWithSpace(
              list.reduce((sum, el) => sum + el.val_ini, 0).toFixed(2)
            ),
            styles: { halign: "right" },
          },
          stock_in: {
            content: this.numberWithSpace(
              list.reduce((sum, el) => sum + el.stock_in, 0).toFixed(2)
            ),
            styles: { halign: "right" },
          },
          val_in: {
            content: this.numberWithSpace(
              list.reduce((sum, el) => sum + el.val_in, 0).toFixed(2)
            ),
            styles: { halign: "right" },
          },
          stock_out: {
            content: this.numberWithSpace(
              list.reduce((sum, el) => sum + el.stock_out, 0).toFixed(2)
            ),
            styles: { halign: "right" },
          },
          val_out: {
            content: this.numberWithSpace(
              list.reduce((sum, el) => sum + el.val_out, 0).toFixed(2)
            ),
            styles: { halign: "right" },
          },
          stock_cmpt: {
            content: this.numberWithSpace(
              list.reduce((sum, el) => sum + el.stock_cmpt, 0).toFixed(2)
            ),
            styles: { halign: "right" },
          },
          val_cmpt: {
            content: this.numberWithSpace(
              list.reduce((sum, el) => sum + el.val_cmpt, 0).toFixed(2)
            ),
            styles: { halign: "right" },
          },
        });
        doc.setFontSize(18);
        doc.setFont("helvetica", "normal");
        if (e == "e") doc.text("Etat des Réceptions", 100, 50);
        if (e == "s") doc.text("Etat des sorites", 100, 50);
        if (e == "c")
          doc.text("Mouvements du Stock Par Compte Comptable", 100, 50);
        doc.setFontSize(9);
        doc.text(
          "Du :" +
            this.datefr(this.exercice.dd) +
            " Au :" +
            this.datefr(this.exercice.df),
          125,
          55
        );
        doc.text("Alger le : " + this.datefr(this.$store.state.today), 250, 50);
        doc.autoTable({
          margin: { top: 60, bottom: marges.margin_bottom + marges.p_h },
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 50;
          },
          headStyles: {
            valign: "middle",
            fillColor: [128, 128, 128],
            fontSize: 9,
          },
          columnStyles: columnStyles,
          head: head,
          body: items,
          columns: columns,
          foot: foot,
          theme: "striped",
          footStyles: {
            fillColor: [128, 128, 128],
            fontSize: 9,
          },
          showHead: "everyPage",
          showFoot: "lastPage",
        });
        addHeaders(doc, this.$store);
        addFooters(doc, this.$store);

        doc.setProperties({ title: "Etat des stocks" });
        doc.output("pdfobjectnewwindow");
      }
    },
    async regul_stock() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir regulariser le stock?",
          { color: "orange darken-3" }
        )
      ) {
        let articles_list = [];
        let articles = [];
        let articles_mvms = [];
        let mvms_detail = [];
        let mvms = [];
        let q_stock = [];
        // this.loading_data = true;
        let v = {
          Kind: parseInt(this.prd_type),
        };
        let r = await this.requette(ARTICLES_MVMS, v);

        if (r) {
          articles_list = r.articles_mvms;
          v = {
            desc: "ASC",
          };
          r = await this.requette(MVMS, v);
          this.loading_data = false;
          if (r) {
            articles_mvms = r.product_mvms;
            let depots = articles_mvms
              .map((elm) => elm.depot_id)
              .filter(onlyUnique);
            let st_neg = [];
            let mv_neg = [];
            for (let i = 0; i < articles_list.length; i++) {
              const article = articles_list[i];
              let article_mvms = articles_mvms.filter(
                (elm) => elm.sens != 0 && elm.article_id == article.id
              );

              for (let index = 0; index < article_mvms.length; index++) {
                const mvm = article_mvms[index];
                let j = -1;
                let qte_actual = 0;
                let u = articles.findIndex((elm) => elm.id == article.id);
                if (u >= 0) {
                  articles[u].date_dern_mvm = mvm.mvm_date;
                  article.pmp = articles[u].pmp;
                } else
                  articles.push({
                    id: article.id,
                    pmp: 1,
                    date_dern_mvm: mvm.mvm_date,
                  });
                //si inventaire
                if (mvm.mvm_type == 13) {
                  q_stock.push({
                    article_id: article.id,
                    depot_id: mvm.depot_id,
                    stock_cmpt: mvm.qte_mvm,
                  });
                  article.pmp = mvm.pu;
                  u = articles.findIndex((elm) => elm.id == article.id);
                  if (u >= 0) articles[u].pmp = article.pmp;
                }
                //si reception
                else if (mvm.mvm_type == 1) {
                  j = q_stock.findIndex(
                    (elm) =>
                      elm.article_id == article.id &&
                      elm.depot_id == mvm.depot_id
                  );
                  if (j >= 0) {
                    qte_actual = q_stock[j].stock_cmpt;
                    q_stock[j].stock_cmpt = qte_actual + mvm.qte_mvm;
                  }
                  if (j == -1)
                    q_stock.push({
                      article_id: article.id,
                      depot_id: mvm.depot_id,
                      stock_cmpt: mvm.qte_mvm,
                    });
                  article.pmp =
                    (article.pmp * qte_actual + mvm.pu * mvm.qte_mvm) /
                    (qte_actual + mvm.qte_mvm);
                  article.pmp =
                    Math.round((article.pmp + Number.EPSILON) * 100000) /
                    100000;
                  u = articles.findIndex((elm) => elm.id == article.id);
                  if (u >= 0) articles[u].pmp = article.pmp;
                } else {
                  //sortie
                  if (mvm.sens == -1 && mvm.destock == "0") {
                    let st = 0;
                    j = q_stock.findIndex(
                      (elm) =>
                        elm.article_id == article.id &&
                        elm.depot_id == mvm.depot_id
                    );

                    if (j >= 0) {
                      st = q_stock[j].stock_cmpt - mvm.qte_mvm;
                      q_stock[j].stock_cmpt = st;
                    } else {
                      st = -mvm.qte_mvm;
                      q_stock.push({
                        article_id: article.id,
                        depot_id: mvm.depot_id,
                        stock_cmpt: st,
                      });
                    }
                    if (st < 0)
                      mv_neg.push({
                        article_id: article.id,
                        error: "Mouvement negatif",
                        mvm_id: mvm.mvm_id,
                        nobon: mvm.nobon,
                        mvm_detail_id: mvm.id,
                      });
                  }
                  //entree
                  if (mvm.sens == 1) {
                    j = q_stock.findIndex(
                      (elm) =>
                        elm.article_id == article.id &&
                        elm.depot_id == mvm.depot_id
                    );
                    if (j >= 0)
                      q_stock[j].stock_cmpt =
                        q_stock[j].stock_cmpt + mvm.qte_mvm;
                    else {
                      q_stock.push({
                        article_id: article.id,
                        depot_id: mvm.depot_id,
                        stock_cmpt: mvm.qte_mvm,
                      });
                    }
                  }
                  if (mvm.pu != article.pmp) {
                    //maj pu mvm
                    mvms_detail.push({
                      id: mvm.id,
                      article_id: article.id,
                      pu: article.pmp,
                    });
                    let h = mvms.findIndex((elm) => elm == mvm.mvm_id);
                    if (h == -1) mvms.push(mvm.mvm_id);
                  }
                }
              }
              for (let index = 0; index < depots.length; index++) {
                const depot = depots[index];
                let n = q_stock.findIndex(
                  (elm) =>
                    elm.article_id == article.id &&
                    elm.depot_id == depot &&
                    elm.stock_cmpt < 0
                );
                if (n >= 0)
                  st_neg.push({
                    article_id: article.id,
                    error: "Stock negatif",
                    depot_id: depot,
                    stock_cmpt: q_stock[n].stock_cmpt,
                  });
              }
              this.taux_trait = ((i / articles_list.length) * 100).toFixed(2);
              this.kbt++;
            }

            //maj stock
            if (articles.length > 0 || mvms_detail > 0 || q_stock > 0)
              this.loading_data = true;
            v = {
              global: 1,
              articles: articles,
              mvms_detail: mvms_detail,
              depots: q_stock,
              mvms: mvms,
            };

            let r = await this.maj(UPDATE_ARTICLES, v);
            this.loading_data = false;
            if (r.ok) {
              if (st_neg.length > 0 || mv_neg.length > 0) {
                this.snackbar_text =
                  "Attention des erreurs de stock/Mouvement negatif detectés consulter la console";
                this.snackbar_color = "error";
                this.snackbar = true;
                if (st_neg.length > 0) {
                  console.log("Stock Negatif");
                  console.log(st_neg);
                }
                if (mv_neg.length > 0) {
                  console.log("Mouvement Negatif");
                  console.log(mv_neg);
                }
              } else {
                this.snackbar_text = "Stock régularisé avec succes";
                this.snackbar_color = "success";
                this.snackbar = true;
              }
            } else {
              this.snackbar_text = "Erreur d'enregistrement inconnue ";
              this.snackbar_color = "error";
              this.snackbar = true;
            }
          }
        }
      }
    },
    DepotChange(item) {
      this.depot = item;
    },
    DepotChange2(item) {
      this.depot2 = item;
      this.alist++;
    },
    async maj(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = { ok: true, data: data.data };
          this.loading = false;
        })
        .catch((error) => {
          r = { ok: false, error: error };
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },

    async save(list, item) {
      //Correction Stock
      if (!this.imp_excel) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir corriger le stock?",
            { color: "orange darken-3" }
          )
        ) {
          let list = [];
          for (let index = 0; index < this.form_list.length; index++) {
            const element = this.form_list[index];
            if (element.changed) {
              let pl = null;
              element.qte_diff = element.stock_cmpt - element.qte_max_old;
              if (this.article.bylist == "1") {
                element.qte_diff = element.qte_stock - element.qte_max_old;
                pl = {
                  id: element.id,
                  article_id: this.article.id,
                  depot_id: element.depot_id,
                  ref: element.ref,
                  ref0: element.ref0,
                  ref1: element.ref1,
                  ref2: element.ref2,
                  ref3: element.ref3,
                  ref4: element.ref4,
                  ref5: element.ref5,
                  qte: element.qte_rest,
                  expire_date: element.expire_date,
                  qte_diff: element.qte_diff,
                  write_uid: this.$store.state.me.id,
                };
              }

              let j = list.findIndex(
                (elm) => elm.mvm_date == this.$store.state.today
              );

              if (j >= 0) {
                let k = list[j].detail.findIndex(
                  (elm) =>
                    elm.article_id == this.article.id &&
                    elm.depot_id == element.depot_id
                );
                if (k >= 0) {
                  list[j].detail[k].qte =
                    parseFloat(list[j].detail[k].qte) + element.qte_diff;
                  list[j].detail[k].pu = this.article.pmp;
                  if (pl) list[j].detail[k].pl.push(pl);
                } else {
                  let l = [];
                  if (pl) l.push(pl);
                  list[j].detail.push({
                    article_id: this.article.id,
                    depot_id: element.depot_id,
                    qte: element.qte_diff,
                    pu: this.article.pmp,
                    fk_unit: element.fk_unit,
                    pl: l,
                  });
                }
              } else {
                let detail = [];
                let l = [];
                if (pl) l.push(pl);
                detail.push({
                  article_id: this.article.id,
                  depot_id: element.depot_id,
                  qte: element.qte_diff,
                  pu: this.article.pmp,
                  fk_unit: element.fk_unit,
                  pl: l,
                });
                list.push({
                  mvm_date: this.$store.state.today,
                  write_uid: this.$store.state.me.id,
                  detail: detail,
                });
              }
              element.changed = false;
            }
          }
          if (list.length > 0) {
            let v = {
              list: list,
            };
            let r;
            r = await this.maj(CORRECTSTOCK, v);
            this.btn_save_dis = true;
            if (r.ok) {
              this.snackbar_text = "Stock Corrigé avec succes";
              this.snackbar_color = "success";
              this.snackbar = true;
              this.isListClose = true;
              this.klista++;
              this.klist++;
            } else {
              let s = r.error.graphQLErrors[0].debugMessage;
              if (
                s.search("1062") > 0 &&
                s.search("products_list_index03") > 0
              ) {
                let s1 = s.indexOf("'", 0);
                let s2 = s.indexOf("'", s1 + 1);
                let k = s.substring(s1 + 1, s2);
                this.snackbar_text = "le code : " + k + " existe déja";
              } else this.snackbar_text = "Erreur d'enregistrement inconnue ";
              this.add_disable = false;
              this.snackbar_color = "error";
              this.snackbar = true;
            }
          }
        }
      }
      //Stock initial
      if (this.imp_excel) {
        let ok = true;
        this.message = null;
        let list = [];
        for (let index = 0; index < this.form_list.length; index++) {
          const element = this.form_list[index];
          if (!element.code_article && ok) {
            this.message =
              "Code Article obligatoire " + " - ligne " + (index + 1);
            this.type_alert = "error";
            this.alert = true;
            ok = false;
            break;
          }
          if (!element.label && ok) {
            this.message =
              "Article introuvable " +
              element.code_article +
              " - ligne " +
              (index + 1);
            this.type_alert = "error";
            this.alert = true;
            ok = false;
            break;
          }
          if (element.bylist == "1" && this.si == "c" && ok) {
            this.message =
              "Article géré par quantité " + " - ligne " + (index + 1);
            this.type_alert = "error";
            this.alert = true;
            ok = false;
            break;
          }
          if (element.bylist == "0" && this.si == "l" && ok) {
            this.message =
              "Article géré par liste " + " - ligne " + (index + 1);
            this.type_alert = "error";
            this.alert = true;
            ok = false;
            break;
          }
          if (
            !element.ref &&
            this.si == "l" &&
            ok &&
            this.$store.state.options[0].code_list == "1"
          ) {
            this.message = "Code Item obligatoire " + " - ligne " + (index + 1);
            this.type_alert = "error";
            this.alert = true;
            ok = false;
            break;
          }
          if (element.nb == null && this.si == "l" && ok) {
            this.message = "Nombre obligatoire " + " - ligne " + (index + 1);
            this.type_alert = "error";
            this.alert = true;
            ok = false;
            break;
          }

          if (!element.depot_id && ok) {
            this.message =
              "Depot obligatoire " +
              element.article_id +
              " - ligne " +
              (index + 1);
            this.type_alert = "error";
            this.alert = true;
            ok = false;
            break;
          }

          if (ok && this.si == "c") {
            let i = this.form_list.findIndex(
              (elm) =>
                elm.article_id == element.article_id &&
                elm.depot_id == element.depot_id
            );
            if (i != index) {
              this.message =
                "Article en double pour le produit " +
                element.code_article +
                " - lignes " +
                (index + 1 + " et " + (i + 1));
              this.type_alert = "error";
              this.alert = true;
              ok = false;
              break;
            }
          }
          if (ok && this.si == "l") {
            let i = this.form_list.findIndex((elm) => elm.ref == element.ref);
            if (i != index) {
              this.message =
                "Code Item en double pour le produit " +
                element.code_article +
                " - lignes " +
                (index + 1 + " et " + (i + 1));
              this.type_alert = "error";
              this.alert = true;
              ok = false;
              break;
            }
          }
          if (typeof element.qte != "number" && ok) {
            this.message =
              "Quantité inccorrecte pour le produit " +
              element.code_article +
              " - ligne " +
              (index + 1);
            this.type_alert = "error";
            this.alert = true;
            ok = false;
            break;
          }
          if (typeof element.pu != "number" && ok) {
            this.message =
              "Prix obligatoire pour le produit " +
              element.code_article +
              " - ligne " +
              (index + 1);
            this.type_alert = "error";
            this.alert = true;
            ok = false;
            break;
          }
          // if (!(element.date instanceof Date) && ok) {
          //   this.message =
          //     "Date inccorrecte pour le produit " +
          //     element.code_article +
          //     " - ligne " +
          //     (index + 1);
          //   this.type_alert = "error";
          //   this.alert = true;
          //   ok = false;
          //   break;
          // }
          if (
            !(element.expire_date instanceof Date) &&
            element.is_expire == "1" &&
            ok
          ) {
            this.message =
              "Date peremption inccorrecte pour le produit " +
              element.code_article +
              " - ligne " +
              (index + 1);
            this.type_alert = "error";
            this.alert = true;
            ok = false;
            break;
          }
          if (element.dernier_inv >= item.date_in && ok) {
            this.message =
              "Date inferieure ou égale à la date du dernier inventaire pour le produit ";
            this.type_alert = "error";
            this.alert = true;
            ok = false;
            break;
          }
          let pl = null;
          if (this.si == "l")
            pl = {
              article_id: element.article_id,
              depot_id: element.depot_id,
              ref: element.ref,
              ref0: element.ref0,
              ref1: element.ref1,
              ref2: element.ref2,
              ref3: element.ref3,
              ref4: element.ref4,
              ref5: element.ref5,
              expire_date: element.expire_date
                ? element.expire_date.toISOString().slice(0, 10)
                : null,
              qte: element.qte,
              factor: parseFloat(element.factor),
              nb: parseInt(element.nb),
              write_uid: this.$store.state.me.id,
            };
          let j = list.findIndex((elm) => elm.mvm_date == item.date_in);

          if (j >= 0) {
            let k = list[j].detail.findIndex(
              (elm) =>
                elm.article_id == element.article_id &&
                elm.depot_id == element.depot_id
            );
            if (k >= 0) {
              list[j].detail[k].qte =
                parseFloat(list[j].detail[k].qte) +
                element.qte * (element.nb ? element.nb : 1);
              list[j].detail[k].pu = element.pu;
              if (pl) list[j].detail[k].pl.push(pl);
            } else {
              let l = [];
              if (pl) l.push(pl);
              list[j].detail.push({
                article_id: element.article_id,
                depot_id: element.depot_id,
                qte: element.qte * (element.nb ? element.nb : 1),
                pu: element.pu,
                fk_unit: element.fk_unit,
                pl: l,
              });
            }
          } else {
            let detail = [];
            let l = [];
            if (pl) l.push(pl);
            detail.push({
              article_id: element.article_id,
              depot_id: element.depot_id,
              qte: element.qte * (element.nb ? element.nb : 1),
              pu: element.pu,
              fk_unit: element.fk_unit,
              pl: l,
            });
            list.push({
              mvm_date: item.date_in,
              write_uid: this.$store.state.me.id,
              detail: detail,
            });
          }
        }

        if (ok) {
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Etês-vous sûr de vouloir mettre a jour le stock initial?",
              { color: "orange darken-3" }
            )
          ) {
            let v = {
              list: list,
            };
            let r;
            r = await this.maj(UPDATE_SI, v);
            if (r.ok) {
              this.snackbar_text =
                "Stock Initial des produits mise a jour avec succes";
              this.snackbar_color = "success";
              this.snackbar = true;
              this.isListClose = true;
              this.klista++;
            } else {
              this.snackbar_text = "Erreur d'enregistrement inconnue ";
              this.snackbar_color = "error";
              this.snackbar = true;
            }
          }
        }
      }
    },

    async excel_si(items) {
      this.title = "";
      // let f = items[0];
      this.alert = false;
      let ok = true;
      let r = await this.requette(ALLARTICLES);
      if (r) this.articles_list = r.allarticles;
      if (
        arraysEqual(
          items.headers,
          this.si == "c"
            ? ["No", "Code Article", "Article", "Qte", "Pmp", "Depot"]
            : [
                "No",
                "Code Article",
                "Article",
                "Code Item",
                "Attribut 1",
                "Attribut 2",
                "Attribut 3",
                "Attribut 4",
                "Attribut 5",
                "Attribut 6",
                "Date peremption",
                "Qte",
                "Pmp",
                "Depot",
                "Nb",
              ]
        ) == false
      ) {
        this.snackbar_text = "format fichier incompatible!";
        this.snackbar_color = "error";
        this.snackbar = true;
        ok = false;
      }

      if (ok) {
        let list = [];

        for (let index = 0; index < items.results.length; index++) {
          const element = items.results[index];
          let elm = {};
          let i = this.articles_list.findIndex(
            (elm) => elm.code == element["Code Article"]
          );
          elm.id = list.length + 1;
          elm.code_article = element["Code Article"];
          if (i >= 0) {
            elm.bylist = this.articles_list[i].bylist;
            elm.is_expire = this.articles_list[i].is_expire;
            elm.dernier_inv = this.articles_list[i].dernier_inv;
            elm.label = this.articles_list[i].label;
            elm.article_id = this.articles_list[i].id;
            elm.tva_tx = this.articles_list[i].tva_tx;
            elm.fk_unit = this.articles_list[i].fk_unit;
            elm.factor = this.articles_list[i].factor;
          }
          i = this.depots.findIndex((elm) => elm.label == element.Depot);
          if (i >= 0) elm.depot_id = this.depots[i].id;

          elm.qte = element.Qte;
          elm.pu = element.Pmp;
          elm.depot = element.Depot;

          if (this.si == "l") {
            elm.ref = element["Code Item"]
              ? element["Code Item"].toString()
              : null;
            elm.ref0 = element["Attribut 1"]
              ? element["Attribut 1"].toString()
              : null;
            elm.ref1 = element["Attribut 2"]
              ? element["Attribut 2"].toString()
              : null;
            elm.ref2 = element["Attribut 3"]
              ? element["Attribut 3"].toString()
              : null;
            elm.ref3 = element["Attribut 4"]
              ? element["Attribut 4"].toString()
              : null;
            elm.ref4 = element["Attribut 5"]
              ? element["Attribut 5"].toString()
              : null;
            elm.ref5 = element["Attribut 6"]
              ? element["Attribut 6"].toString()
              : null;
            elm.expire_date = element["Date peremption"]
              ? element["Date peremption"]
              : null;
            elm.nb = element["Nb"] ? element["Nb"].toString() : 0;
          }
          list.push(elm);
        }
        if (list.length > 0) {
          this.btn_save_dis = false;
          this.form_list = list;
          this.frml++;
        }
      }
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop] ? a[prop].localeCompare(b[prop]) : "";
      };
    },
    OpenSIForm(e) {
      this.form_list = [];
      this.periode = true;
      this.si = e;
      if (e == "c") {
        this.formheaders = this.sicheaders;
        this.width = "1000px";
      }
      if (e == "l") {
        this.formheaders = this.silheaders;
        this.width = "1400px";
      }
      this.btn_save = true;
      this.imp_excel = true;
      this.isListClose = false;

      this.frml++;
    },
    item_change(item) {
      item.changed = true;
      this.btn_save_dis = false;
      this.save();
    },
    list_change(item) {
      item.changed = true;
      this.btn_save_dis = false;
    },
    CloseDetail() {
      this.isListClose = true;
      this.btn_save = false;
      this.imp_excel = false;
    },

    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    unfilrer() {
      this.unfilter = false;
      this.categories = [];
      this.attributs = [];
      this.template = 0;
      this.$store.dispatch("Changed", true);
    },
    OpenFilterForm() {
      this.isFilterClosed = false;
    },
    closeFilterForm(cat, temp, att) {
      if (cat || temp || att) {
        this.unfilter = true;
        this.categories = cat;
        this.template = parseInt(temp);
        this.attributs = att;
        this.klist++;
      }
      this.isFilterClosed = true;
    },
    ProductChange(item) {
      this.product = item;
      this.selitem = item;
      this.article = {};
      this.mvmsheaders[6].dec = this.product.decimal;
      this.mvmsheaders[7].dec = this.product.decimal;
      this.mvmsheaders[8].hiden = this.product.is_moule == "0";
      this.kd++;
      this.kf++;
    },
    ArticleChange(item) {
      this.article = item;
      this.articles_headers[7].slot =
        (this.$store.state.auth.includes("02092") ||
          this.$store.state.isadmin) &&
        this.article.bylist == "0"
          ? "href22"
          : "cur";

      this.depot2_headers[2].slot = this.articles_headers[7].slot;
      this.depot2_headers[3].hiden = this.article.bylist == "1";
      this.depot2_headers[4].hiden = !(
        (this.$store.state.auth.includes("02092") ||
          this.$store.state.isadmin) &&
        this.article.bylist == "0"
      );
      if (item.stock_amalgame) {
        if (item.stock_amalgame.length > 0) {
          this.form_list = item.stock_amalgame;
          this.form_list.forEach((element) => {
            element.qte_max_old = element.stock_cmpt;
          });
        }
      }
      if (item.stock_depots) {
        if (item.stock_depots.length > 0) {
          this.form_list = item.stock_depots;
          this.form_list.forEach((element) => {
            element.qte_max_old = element.stock_cmpt;
          });
        }
      }
      this.mvmsheaders[6].dec = this.article.decimal;
      this.mvmsheaders[7].dec = this.article.decimal;
      this.mvmsheaders[8].hiden = this.article.is_moule == "0";
      this.dkey2++;
    },
    async show_stock() {
      this.editer = true;
      this.btn_save = true;
      this.imp_excel = false;
      this.title =
        "Stock/Depot : " +
        this.article.article_name +
        (this.article.ref_ext ? " Ref.: " + this.article.ref_ext : "");
      this.width = "800px";
      this.formheaders = this.depot2_headers;
      this.form_list = this.stock_depots;
      this.form_list.forEach((element) => {
        element.qte_max_old = element.stock_cmpt;
      });
      this.periode = false;
      this.isListClose = false;
      this.frml++;
    },
    show_boxs(item) {
      this.editer == false;
      this.btn_save = false;
      this.imp_excel = false;
      this.title =
        "Liste des " +
        this.depot.box +
        "s de " +
        this.article.article_name +
        (this.article.ref_ext ? " Ref.: " + this.article.ref_ext : "");
      this.width = "1000px";
      this.boxheaders[1].text = this.depot.rack ? this.depot.rack : "Rack";
      this.boxheaders[2].text = this.depot.box ? this.depot.box : "Box";
      this.boxheaders[3].text = this.depot.range ? this.depot.range : "Niveau";
      this.formheaders = this.boxheaders;
      if (this.depot.stock_boxs)
        this.form_list = this.depot.stock_boxs.filter(
          (elm) => elm.depot_id == item.depot_id
        );
      this.isListClose = false;
      this.frml++;
    },
    show_mvms_d(item) {
      this.show_mvms(item, this.depot2.id);
    },
    async show_mvms(item, depot) {
      this.editer == false;
      this.btn_save = false;
      this.imp_excel = false;
      this.loading_mvm = true;
      this.title =
        "Liste des Mouvements : " +
        item.article_name +
        (item.ref_ext ? " Ref.: " + item.ref_ext : "");
      this.width = "1600px";
      this.formheaders = this.mvmsheaders;
      if (item.id) {
        let v = {
          depot_id: depot ? depot : null,
          article_id: [item.article_id ? item.article_id : item.id],
        };
        let r = await this.requette(MVMS, v);
        if (r) this.form_list = r.product_mvms;
        this.periode = false;
        this.isListClose = false;
        this.loading_mvm = false;
        this.frml++;
      }
    },
    async show_list_d(item) {
      this.show_list(item, this.depot2.id);
    },
    async show_list(item, depot) {
      this.btn_save =
        this.$store.state.auth.includes("02092") || this.$store.state.isadmin;
      this.colisheaders[2].text = this.depot.box ? this.depot.box : "Box";
      this.colisheaders[16].text = this.depot.rack ? this.depot.rack : "Rack";
      this.colisheaders[17].text = this.depot.range
        ? this.depot.range
        : "Niveau";
      this.colisheaders[2].hiden = this.depot.NbRack == 0;
      this.colisheaders[5].hiden =
        this.$store.state.options[0].code_list == "0";
      this.colisheaders[12].hiden = item.is_expire == "0";
      this.colisheaders[12].edit = item.is_expire == "1";
      this.colisheaders[11].slot = this.btn_save ? "href22" : "cur";
      this.editer = this.btn_save;
      this.colisheaders[13].hiden = !this.btn_save;
      this.imp_excel = false;
      this.width = "1600px";
      this.loading_liste = true;
      this.title =
        "Stock Détaillé : " +
        item.product_name +
        " / " +
        item.tier_name +
        " / " +
        item.article_name;
      let headers = [];

      this.colisheaders.forEach((element) => {
        headers.push(element);
      });
      // headers[4].hiden = this.product.tosell == 0 && this.product.tobuy == 0;
      // headers[5].hiden = this.product.tobuy == 0;
      //headers[4].hiden = this.product.tosell == 0;
      let colis_attributs = [];
      headers[3].text =
        "Qte Rcpt " + (item.unit_buy ? "(" + item.unit_buy + ")" : "");
      headers[3].dec = item.decimal;
      headers[11].text = "Stock " + (item.unit ? "(" + item.unit + ")" : "");
      headers[11].dec = this.product.decimal;

      colis_attributs = item.tabcolisattributs ? item.tabcolisattributs : [];
      for (let index = 0; index < colis_attributs.length; index++) {
        const element = colis_attributs[index];
        let i = this.colis_attributs.findIndex((elm) => elm.id == element);
        let lv = this.values_list.filter((elm) => elm.attribute_id == element);
        if (i >= 0) {
          let ls = [];

          lv.forEach((element) => {
            ls.push(element.name);
          });
          let elm = {
            text: this.colis_attributs[i].caption
              ? this.colis_attributs[i].caption
              : this.colis_attributs[i].name,
            value: "ref" + index,
            selected: true,
            edit: true,
            readonly: false,
            rules: [
              (v) =>
                !!v ||
                (this.colis_attributs[i].caption
                  ? this.colis_attributs[i].caption
                  : this.colis_attributs[i].name) + " obligatoire",
            ],
            type: lv.length > 0 ? "autocomplete" : "text",
            select_list: ls,
            show_list: ls,
            sm: 6,
            md: 6,
          };
          headers.splice(index + 6, 0, elm);
        }
      }
      this.formheaders = headers;
      let v = {
        depot_id: depot ? depot : null,
        article_id: [item.article_id ? item.article_id : item.id],
        stock_id: 1,
      };
      let r = await this.requette(PRODUCT_LIST, v);

      if (r) {
        this.form_list = r.product_list;
        this.form_list.forEach((element) => {
          element.qte_max_old = element.qte_max;
          element.qte_max = null;
        });
        this.periode = false;
        if (this.form_list.length > 0) this.isListClose = false;
      }
      this.loading_liste = false;
      this.frml++;
    },
  },
};
</script>
